
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import dayjs from "dayjs";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import DataImport from "@/components/scope/data-import.vue";
import SwitchStatus from "@/components/scope/switch-status.vue";
import { Pageniation as Table, TableRowsSelect } from "@/utils/decorator";
import t from "@common/src/i18n/t";
const consult = namespace("consult");
const base = namespace("base");
@Component({
  components: { TableEnableFlag, DataImport, SwitchStatus },
  filters: {
    NameListFilter: val => {
      return val ? val.join("/") : "-";
    }
  }
})
@Table("loadListData")
@TableRowsSelect({
  warningMsg: t("setting.select-spare-parts"),
  updateStatusFuncName: "confirmUpdateStatus",
  tableRefKey: "table"
})
export default class ConsultList extends Vue {
  @consult.Action queryContentPage;
  @consult.Action queryContentClassPage;
  @consult.Action queryUserAccountList;
  @consult.Action releaseContent;
  @consult.Action withdrawContent;
  @base.Action getDictionaryList;
  searchForm = {
    title: "",
    contentCode: "",
    classCode: "",
    status: "",
    releaseTimeStart: "",
    releaseTimeEnd: "",
    releaseRange: "",
    releaseUser: ""
  };
  dayjs = dayjs;
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  sortForm: {};
  contentList: any[] = [];
  contentClassList: any[] = [];
  userAccountList: any[] = [];
  contentStatusList: any[] = [];
  contentRangeList: any[] = [];
  get searchList() {
    return [
      {
        label: t("v210831.information-title"),
        type: "input",
        value: "",
        prop: "title"
      },
      {
        label: t("v210831.information-number"),
        type: "input",
        value: "",
        prop: "contentCode"
      },
      {
        label: t("test.category"),
        type: "select",
        selectList: this.contentClassList,
        value: "",
        prop: "classCode"
      },
      {
        label: t("v210801.state"),
        type: "select",
        selectList: this.contentStatusList,
        value: "",
        prop: "status"
      },
      {
        label: t("v210831.release-date"),
        type: "daterange",
        value: "",
        prop: "releaseTime"
      },
      {
        label: t("v210831.release-scope"),
        type: "select",
        selectList: this.contentRangeList,
        value: "",
        prop: "releaseRange"
      },
      {
        label: t("v210831.publisher"),
        type: "select",
        selectList: this.userAccountList,
        value: "",
        prop: "releaseUser"
      }
    ];
  }
  categoryList: any[] = [];
  brandList: any[] = [];
  created() {
    this.init();
  }
  async init() {
    this.loadListData();
    this.getContentClassList();
    this.getUserAccount();
    // 状态字典List
    this.getDictionaryList("content_status").then(data => {
      this.contentStatusList = data;
    });
    // 发布范围字典List
    this.getDictionaryList("store_type").then(data => {
      this.contentRangeList = data;
    });
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryContentPage({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.contentList = data.data.list;
      return data;
    });
  }
  @TableRowsSelect.updateMethod
  // 获取分类列表
  getContentClassList() {
    this.queryContentClassPage({
      pageNum: 1,
      pageSize: 999
    }).then(data => {
      this.contentClassList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.className;
            item.value = item.classCode;
            return item;
          })
        : [];
    });
  }
  getUserAccount() {
    this.queryUserAccountList({
      pageNum: 1,
      pageSize: 999
    }).then(data => {
      this.userAccountList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.firstName;
            item.value = item.userCode;
            return item;
          })
        : [];
    });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "releaseTime") {
          this.searchForm["releaseTimeStart"] = item.value[0]
            ? dayjs(item.value[0]).format("YYYY-MM-DD") + " 00:00:00"
            : "";
          this.searchForm["releaseTimeEnd"] = item.value[1]
            ? dayjs(item.value[1]).format("YYYY-MM-DD") + " 23:59:59"
            : "";
        } else if (item.prop === "categoryCode") {
          this.searchForm[item.prop] = item.value[item.value.length - 1];
        } else {
          this.searchForm[item.prop] = item.value;
        }
      }
    });
    console.log(this.searchForm);
    this.loadListData();
  }
  // 操作
  // 发布
  handleRelease(row) {
    this.releaseContent({ contentCode: row.contentCode }).then(() => {
      this.$message.success(t("v210831.published-successfully"));
      this.loadListData();
    });
  }
  // 下架
  handleWithdraw(row) {
    this.withdrawContent({ contentCode: row.contentCode }).then(() => {
      this.$message.success(t("v210831.removed-successfully"));
      this.loadListData();
    });
  }
  // 编辑
  handleEdit(row) {
    this.$router.push("/consult/consult-edit/" + row.contentCode);
  }
  // 资讯详情
  handleView(row) {
    this.$router.push("/consult/consult-view/" + row.contentCode);
  }
  handleCommand(command) {
    console.log("[ command ]", command);
    if (command === "edit") {
      this.handleEdit(this.hoverRow);
    } else if (command === "copy") {
      console.log("[ copy ]", this.hoverRow);
      this.$router.push(`/consult/consult-add?id=${this.hoverRow.contentCode}`);
    }
  }
  hoverRow = undefined;
  handleSetCurrentRow(visible, row) {
    this.hoverRow = row;
  }
  /////
}
